.wrapper{
  min-height: 10em;
  border: 1px solid white;
  border-radius: 4px;
  background: rgba(255,255,255,0.3);
}
.pre{
  white-space: pre-line;
  text-align: left;
  padding: 12px 5px;
  position: relative;
  z-index: 10;
  background-color: #21D4FD;
  background-image: linear-gradient(19deg, #21D4FD 0%, #B721FF 66%, #ff11da 99%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100% 100%;
}
