.canvas{
  /* background: linear-gradient(to top,#cbebdb 0,#3794c0 120%); */
}
.main{
  padding: 0 12px;
  color: #3794c0 ;
}
.granim{
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}
